import React, { useCallback, useEffect, useRef } from 'react';
import { setContext, getCurrentScope } from '@sentry/react';
import styled, { css } from 'styled-components';
import { animated, useTransition } from 'react-spring';
import { isNil } from 'ramda';

import {
  PostMessage,
  Position,
  LayoutT,
  ResizeMessage,
  Appearance,
} from '../../shared/types';

import log from '../../utils/log';
import { iframeSizes } from '../../shared/constants';
import isMobile from '../../utils/isMobile';
import cleanPath from '../../utils/cleanPath';
import { ROOT } from '../../constants/paths';
import { CLOSE_BUTTON_SIZE } from '../TriggerComponent/components/Large/Variants/Initial/components/CloseButton';

import TriggerComponent from '../TriggerComponent';
import Header, { HeaderAction } from '../Header';
import Footer from '../Footer';

import useWidgetContext from '../../hooks/useWidgetContext';
import useMessaging from '../../hooks/useMessaging';
import useDismiss from '../../hooks/useDismiss';
import useCopy from '../../hooks/useCopy';
import usePathPrefix from '../../hooks/usePathPrefix';
import useFireTrackingEvent from '../../hooks/useFireTrackingEvent';

import useFrameSizeSelector from '../../hooks/useFrameSizeSelector';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { layoutSelector, widgetState } from '../../state/widgetState';

export type Props = {};

const IframeLayout: React.FC<Props> = ({ ...rest }) => {
  const navigate = useNavigate();
  const pageParams = useParams();
  const location = useLocation();
  const pathPrefix = usePathPrefix();
  const fireTrackingEvent = useFireTrackingEvent();
  const context = useWidgetContext();

  const { highlighted, positions, apps, appearances: sizes } = context;
  const { actions: actionLabels } = useCopy();

  const [isDismissed, onDismiss, onClearDismiss] = useDismiss();
  const frameSizeSelector = useFrameSizeSelector();

  const [{ expanded, fullscreen, appsOpened }, setWidgetState] =
    useRecoilState(widgetState);
  const layout = useRecoilValue(layoutSelector);

  // We always want to load small on mobile so we don't take up the entire screen.
  const currentTriggerAppearance = isMobile()
    ? Appearance.Small
    : sizes[frameSizeSelector];

  const containerRef = useRef<HTMLDivElement | null>(null);

  const root = pathPrefix + ROOT;

  const onMessage = useCallback(
    (message: PostMessage) => {
      log('Received message in widget', message);

      switch (message.type) {
        case 'open':
          onClearDismiss();
          setWidgetState(prev => ({ ...prev, expanded: true }));
          let pathname = `${pathPrefix}${message.payload.path ?? ROOT}`;

          const matched = message.payload.path?.match(/^\/apps\/(.+)/);
          // If the app is not inside the app context its not enabled we we redirect to not found page
          if (matched && matched[1] && isNil(context.apps[matched[1]])) {
            pathname = `${pathPrefix}/not-found`;
          }

          const params = new URLSearchParams({
            queryParams: message.payload.queryParams ?? '',
          });
          navigate(`${pathname}?${params.toString()}`);

          const eventName = (():
            | 'DeepLink'
            | 'ExpandWidget'
            | 'OpenCommand' => {
            if (message.payload.triggerType === 'deepLink') return 'DeepLink';
            if (message.payload.triggerType === 'command') return 'OpenCommand';
            return 'ExpandWidget';
          })();

          fireTrackingEvent({
            event: eventName,
            path: message.payload.path ?? ROOT,
            triggerType: message.payload.triggerType,
          });
          break;
        case 'close':
          setWidgetState(prev => ({ ...prev, expanded: false }));
          navigate(`${root}${location.search}`);
          fireTrackingEvent({ event: 'CollapseWidget' });
          break;
        case 'track': {
          const slug = pageParams.slug;
          const app =
            slug && typeof slug === 'string' ? context.apps[slug] : null;

          if (app) {
            fireTrackingEvent({
              event: 'TrackMessage',
              message,
            });
          }
          break;
        }
        case 'clearDismiss':
          onClearDismiss();
          navigate(`${root}${location.search}`);
          break;

        // Forward message up to bootstrap and external window
        case 'iframeBridgeLoaded':
          sendMessage({ type: 'iframeBridgeLoaded' });
          break;
        default:
          break;
      }
    },
    [fireTrackingEvent, onClearDismiss, pathPrefix, root, pageParams],
  );

  const sendMessage = useMessaging(onMessage);

  const visibleApps = Object.values(apps).filter(app => !app.hidden);
  const hasOnlyOneVisibleApp = visibleApps.length === 1;

  const slug = pageParams.slug;
  const currentApp =
    slug && typeof slug === 'string' ? context.apps[slug] : null;

  useEffect(() => {
    // When the widget has been dismissed we don't want to fire any resize events
    if (isDismissed) return;

    const collapsedFrame = iframeSizes.collapsed;

    const collapsedFrameAppearance =
      context.preventDefaultOpening === true
        ? iframeSizes.hidden[frameSizeSelector]
        : collapsedFrame[currentTriggerAppearance][frameSizeSelector];

    let resizePayload: ResizeMessage['payload'] = {
      height: '0',
      width: '0',
      layout,
      position: positions[frameSizeSelector],
      fullscreen,
    };

    if (frameSizeSelector === 'mobile') {
      const additionalSpace = hasOnlyOneVisibleApp ? '10px' : '0px';

      const expectations =
        expanded && layout !== 'collapsed'
          ? {
              ...iframeSizes[layout][frameSizeSelector],
            }
          : {
              width:
                currentTriggerAppearance === Appearance.Large
                  ? `calc(${collapsedFrameAppearance.width})`
                  : `calc(${collapsedFrameAppearance.width} + ${CLOSE_BUTTON_SIZE}px)`,
              height:
                currentTriggerAppearance === Appearance.Large
                  ? `calc(${collapsedFrameAppearance.height} + ${additionalSpace})`
                  : `calc(${collapsedFrameAppearance.height} + ${CLOSE_BUTTON_SIZE}px)`,
            };

      resizePayload = { ...resizePayload, ...expectations };
    } else {
      const additionalSpace =
        currentTriggerAppearance === Appearance.Small
          ? `${CLOSE_BUTTON_SIZE}px`
          : hasOnlyOneVisibleApp
            ? '0px'
            : '16px';

      const expectations =
        expanded && layout !== 'collapsed'
          ? {
              width: `calc(${
                iframeSizes[layout][frameSizeSelector].width
              } - ${layout === 'wide' ? CLOSE_BUTTON_SIZE : 0}px)`,
              height: `calc(${
                iframeSizes[layout][frameSizeSelector].height
              } - ${layout === 'wide' ? CLOSE_BUTTON_SIZE : 0}px)`,
              position: positions[frameSizeSelector],
              layout,
              fullscreen,
            }
          : {
              width: `calc(${collapsedFrameAppearance.width} + ${additionalSpace})`,
              height: `calc(${collapsedFrameAppearance.height} + ${additionalSpace})`,
              position: positions[frameSizeSelector],
              layout,
              fullscreen,
            };

      resizePayload = { ...resizePayload, ...expectations };
    }

    sendMessage({
      type: 'resize',
      payload: resizePayload,
    });
  }, [
    fullscreen,
    layout,
    sendMessage,
    expanded,
    frameSizeSelector,
    positions,
    currentTriggerAppearance,
    hasOnlyOneVisibleApp,
    isDismissed,
    location,
  ]);

  useEffect(() => {
    sendMessage({
      type: 'widgetReady',
      payload: {},
    });
  }, [sendMessage]);

  useEffect(() => {
    setContext('widget-context', {
      ...context,
    });

    return () => {
      // When we unmount the layout we need to clear the scope
      // https://docs.sentry.io/platforms/javascript/guides/nextjs/enriching-events/context/#clearing-context
      getCurrentScope().clear();
    };
  }, [context]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && layout === 'fullscreen') {
        setWidgetState(prev => ({
          ...prev,
          fullscreen: false,
          layout: 'wide',
        }));
      }
    },
    [layout],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    const cleanedPath = cleanPath(location.pathname);
    fireTrackingEvent({
      event: 'VirtualPageView',
      path: cleanedPath,
    });
  }, [location, fireTrackingEvent]);

  const transitions = useTransition(expanded, {
    from: { height: '0%', opacity: 0 },
    enter: {
      height: '100%',
      opacity: 1,
    },
    leave: { height: '0%', opacity: 0 },
    config: {
      tension: 180,
      mass: 1,
      friction: 10,
      clamp: true,
    },
  });

  const actions: Array<HeaderAction> = [
    {
      label: actionLabels.goBackToOverview,
      icon: 'arrow-left',
      onClick: () => {
        if (hasOnlyOneVisibleApp) {
          setWidgetState(prev => ({
            ...prev,
            expanded: false,
            layout: 'collapsed',
          }));
          return navigate(`${root}${location.search}`);
        }

        setWidgetState(prev => ({
          ...prev,
          fullscreen: false,
          layout: 'narrow',
        }));

        return navigate(`${root}${location.search}`);
      },
    },
  ];

  if (frameSizeSelector !== 'mobile') {
    actions.push({
      label: actionLabels.fullscreen,
      icon: 'fullscreen',
      onClick: () =>
        setWidgetState(prev => ({ ...prev, fullscreen: !prev.fullscreen })),
    });
  }

  const onClose = useCallback(() => {
    setWidgetState(prev => ({ ...prev, expanded: false, layout: 'collapsed' }));
    sendMessage({
      type: 'resize',
      payload: {
        ...iframeSizes.collapsed[currentTriggerAppearance][frameSizeSelector],
        position: positions[frameSizeSelector],
        layout: 'collapsed',
      },
    });

    fireTrackingEvent({
      event: 'CollapseWidget',
    });

    navigate(`${root}${location.search}`);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [layout, frameSizeSelector, currentTriggerAppearance]);

  if (
    isDismissed === true ||
    (context.preventDefaultOpening === true && expanded === false)
  ) {
    return null;
  }

  return (
    <Container ref={containerRef}>
      {transitions(
        (styles, expanded) =>
          expanded === true && (
            <Overlay
              {...rest}
              $layout={layout}
              $position={positions[frameSizeSelector]}
            >
              <WidgetContainer $layout={layout} style={styles}>
                <Header
                  appearance={layout}
                  onClose={onClose}
                  actions={actions}
                />
                <Inner
                  $bypassResizer={currentApp ? currentApp.bypassResizer : false}
                >
                  <Outlet />
                </Inner>
                <Footer />
              </WidgetContainer>
            </Overlay>
          ),
      )}
      {!expanded && visibleApps.length > 0 && (
        <TriggerComponent
          dataTestId="layout-trigger-button"
          size={currentTriggerAppearance}
          position={positions[frameSizeSelector]}
          highlight={highlighted}
          onDismiss={onDismiss}
          hasOnlyOneApp={hasOnlyOneVisibleApp}
          onTrigger={path => {
            const nextPath = `${pathPrefix}${path}`;
            const cleanedPath = cleanPath(nextPath);

            const nextAppsOpened =
              cleanedPath !== ROOT && !appsOpened.includes(cleanedPath)
                ? appsOpened
                : [...appsOpened, cleanedPath];

            navigate(`${pathPrefix}${path}${location.search}`);
            setWidgetState(prev => ({
              ...prev,
              expanded: true,
              layout: cleanedPath === ROOT ? 'narrow' : 'wide',
              appsOpened: nextAppsOpened,
            }));

            sendMessage({
              type: 'resize',
              payload: {
                ...iframeSizes.narrow[frameSizeSelector],
                position: positions[frameSizeSelector],
                layout: layout,
              },
            });
          }}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const Overlay = styled.div<{
  $layout: LayoutT;
  $position: Position;
}>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  align-self: flex-start;
`;

export const scrollBarStyles = css`
  &::-webkit-scrollbar {
    width: 0.5em;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color('secondary')};
    border-radius: ${({ theme }) => theme.getSystem().border.radius.base};
    outline: none;
  }
`;

const WidgetContainer = styled(animated.div)<{ $layout: LayoutT; style: any }>(
  ({ theme }) => css`
    background-color: #fff;
    border-radius: ${theme.getSystem().border.radius.base};
    box-shadow: ${theme.boxShadow('s')};
    width: 100%;

    position: relative;

    display: flex;
    flex-direction: column;
  `,
);

const Inner = styled.div<{ $bypassResizer?: boolean }>(
  ({ $bypassResizer }) => css`
    height: 100%;
    width: 100%;

    overflow-y: ${$bypassResizer ? 'auto' : 'scroll'};

    ${scrollBarStyles}
  `,
);

export default IframeLayout;
