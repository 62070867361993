import useWidgetContext from '../../hooks/useWidgetContext';

const isStandaloneAppPath = (): boolean => {
  const { accountId } = useWidgetContext();

  // Returns a match when url includes /-/${accountId}/s
  const regexp = new RegExp(`\/-\/${accountId}\/s`);

  const matches = location.pathname.match(regexp);

  return matches !== null;
};

export default isStandaloneAppPath;
