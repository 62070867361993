import React from 'react';
import styled from 'styled-components';
import isStandaloneAppPath from '../../utils/isStandaloneAppPath';

export type Props = {
  dataTestId?: string;
  src: string;
  logoLink?: string;
};

const Logo: React.FC<Props> = ({ dataTestId, src, logoLink, ...rest }) => {
  if (isStandaloneAppPath() && logoLink)
    return (
      <a href={logoLink} target="_blank" rel="noopener noreferrer">
        <LogoImage src={src} data-testid={dataTestId} {...rest} />
      </a>
    );

  return <LogoImage src={src} data-testid={dataTestId} {...rest} />;
};

const MAX_HEIGHT = 50;
const MAX_WIDTH = 155;
const LogoImage = styled.img`
  max-width: ${MAX_WIDTH}px;
  max-height: ${MAX_HEIGHT}px;
  display: block;
  width: auto;
  height: max-content;
`;

export default Logo;
